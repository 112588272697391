import React, {useState} from 'react';
import addToMailchimp from "gatsby-plugin-mailchimp"


const MailchimpPage = () => {
    const [state, setState] = useState({
        email: "jayaintertechmedia@gmail.com",
        result: null
    })

    const handleSubmit = async e => {
        e.preventDefault();
        const result = await addToMailchimp(state.email);
        setState({
            ...state,
            result: result
        })
    }
    return (
        <>
            <button type="submit" onClick={handleSubmit}>Submit</button>
        </>
    )
}

export default MailchimpPage